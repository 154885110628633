import React from 'react';

class E_ResourcesSearch extends React.Component {
    componentDidMount() {
        // Remove any existing Google CSE script
        let existingScript = document.querySelector('script[src="https://cse.google.com/cse.js?cx=004943028205502239300:ocu6yqob3lg"]');
        if (existingScript) {
            existingScript.remove();
        }

        // Create and append a new script element for Google CSE
        const script = document.createElement('script');
        script.src = "https://cse.google.com/cse.js?cx=004943028205502239300:ocu6yqob3lg";
        script.async = true;
        document.body.appendChild(script);
    }

    render() {
        return (
            <div style={{ height: '80vh', overflow: 'auto' }}>
                {/* You can add a heading or other content here */}
              
                {/* This div will contain the Google search input and results */}
                <div className="gcse-search"></div>
            </div>
        );
    }
}

export default E_ResourcesSearch;
